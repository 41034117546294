<template>
  <VBtn v-if="link" class="float-right ml-2" :href="link" target="_blank" depressed color="info lighten-1">
    Инструкция
  </VBtn>
</template>

<script>
import { mapGetters } from 'vuex';
import {ROLES} from '@/store/user/enums';

export default {
  name: 'InstructionButton',
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    link() {
      if (this.isContragent) return '/upload/pko_pnr/Инструкция_ПКО_ПНК_Контрагент.pdf';
      if (this.isReviewer) return '/upload/pko_pnr/Инструкция_ПКО_ПНК_Согласующий.pdf';
      return '';
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkopnr');
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkopnr');
    },
  }
}
</script>

<style scoped>

</style>
