<template>
  <div v-if="roles.length" :class="$style.root">
    <div :class="$style.label">Войти как:</div>
    <VBtnToggle :value="role" @change="onUpdate" dense>
      <VBtn v-for="{text, value} in roles" :value="value" :key="value">
        {{text}}
      </VBtn>
    </VBtnToggle>
  </div>
</template>

<script>
import { get, reject } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RoleSwitcher',
  computed: {
    ...mapGetters({
      user: 'user/current',
    }),
    role() {
      return get(this.user, 'role.pkopnr.code');
    },
    roles() {
      return reject(get(this.user, 'role.pkopnr.options'), { value: this.role });
    },
  },
  methods: {
    ...mapActions({
      fetchCurrent: 'user/fetchCurrent',
      setRole: 'user/setRole',
    }),
    onUpdate(code) {
      if (code) this.setRole({ pkopnr: code }).then(() => {
        this.fetchCurrent();
      });
    }
  }
}
</script>

<style module lang="scss">
.root {
  display: flex;
  align-items: center;
}
.label {
  margin-right: 10px;
}
</style>
